import { useMemo, useRef } from 'react';
import useIntersectionObserver from './useIntersection';

export const useDetectVisible = () => {
  const ref = useRef<HTMLDivElement>(null);

  const div = useMemo(() => {
    return <div ref={ref}>&nbsp;</div>;
  }, []);

  const intersection = useIntersectionObserver(ref, { threshold: 0.5 });

  return { isVisible: !!intersection?.isIntersecting, detector: div };
};
